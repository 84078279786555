<template>
  <el-dialog v-model="shouldShow" title="Lista de Preços">
    <div v-if="hasError">
      <el-row type="flex" align="center" justify="center">
        <h4>Ocorreu um erro a obter os dados do envio</h4>
      </el-row>
    </div>
    <el-row v-for="c in Prices" :key="c" type="flex" justify="space-between">
      <el-col :md="14" align="left">
        <el-row type="flex" justify="start"
          ><span>{{ c.standard }}</span></el-row
        >
      </el-col>

      <el-col :md="10">
        <base-input v-model="c.price" type="money"></base-input>
      </el-col>
    </el-row>
    <el-row v-if="!prices" type="flex" justify="center">
      <small class="text-muted">Nenhum preço foi cadastrado</small>
    </el-row>
    <template #footer>
      <el-row justify="end" type="flex">
        <el-button
          type="text"
          class="button"
          @click="$emit('update-prices', Prices) | $emit('close-modal')"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import { ElLoading } from "element-plus";
import BaseInput from "../../components/BaseInput.vue";
export default {
  props: ["partner", "showModal"],
  emits: ["close-modal", "update-prices"],
  components: { BaseInput },
  data() {
    return {
      isLoading: true,
      hasError: false,
      partner_: this.partner,
      standards: null,
      prices: null,
      inputPreference: null,
      showInputPreference: false,
    };
  },
  watch: {
    partner(v) {
      this.partner_ = v || [];
      this.setupPrices();
    },
    standards(v) {
      if (v?.length) {
        this.setupPrices();
      }
    },
  },
  mounted() {
    this.fetchStandards();
  },
  computed: {
    Prices() {
      return this.prices || [];
    },
    Standards() {
      return this.standards || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    setupPrices() {
      if (this.standards)
        this.prices = this.standards.map((c) => ({
          standard: c.value,
          price:
            this.partner?.prices?.find((p) => p.standard === c.value)?.price ||
            0,
        }));
    },
    fetchStandards() {
      const url = new URL(`${this.$store.state.apiUrl}system/preferences`);
      url.search = new URLSearchParams({ key: "defined_standards" });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.standards = json))
        .finally(() => (this.isLoading = false));
    },
    savePreference() {
      this.$emit("input-preference", {
        key: this.preferenceKey,
        value: this.inputPreference,
      });
      this.inputPreference = "";
      this.showInputPreference = false;
    },
    removePreference(p) {
      this.$emit("remove-preference", p);
    },
  },
  name: "SystemInputPrefencesModal",
};
</script>
<style>
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.text-muted {
  color: grey !important;
  margin-top: 5px !important;
}
</style>