<template>
  <el-dialog v-model="shouldShow">
    <el-loading v-loading="isLoading">
      <el-empty v-if="!imageUrl"></el-empty>
      <el-image v-else :src="imageUrl" @load="isLoading = false"></el-image>
    </el-loading>
  </el-dialog>
</template>
<script>
export default {
  props: ["imageUrl", "showModal"],
  emits: ["close-modal"],
  data() {
    return {
      hasImage: false,
      isLoading: true,
    };
  },
  watch: {
    imageUrl(v) {
      if (v) this.fetchImage();
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    fetchImage() {
      this.isLoading = true;
    },
  },
  name: "ImageViewerModal",
};
</script>
<style>
</style>