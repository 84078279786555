<template>
  <el-card v-loading="isLoading" shadow>
    <el-descriptions
      class="margin-top"
      :title="partner?.name"
      :column="3"
      border
    >
      <template #extra>
        <el-button-group>
          <el-popconfirm
            @confirm="setPartnerStatus(!partner.is_active)"
            :title="
              partner?.is_active
                ? 'Bloquear parceiro?'
                : 'Desbloquear parceiro?'
            "
            ><template #reference>
              <el-button
                onlyIcon
                :type="partner?.is_active ? 'primary' : 'warning'"
                circle
              >
                <i
                  :class="{
                    'el-icon-lock': !partner?.is_active,
                    'el-icon-unlock': partner?.is_active,
                  }"
                ></i></el-button></template
          ></el-popconfirm>
          <el-popconfirm
            @confirm="deletePartner()"
            title="
              Remover parceiro?
            "
            ><template #reference>
              <el-button onlyIcon type="danger" circle>
                <i class="el-icon-delete"></i></el-button></template
          ></el-popconfirm>
        </el-button-group>
      </template>
      <template v-if="partner?.type === 'legal'">
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-tickets"></i>
            Nome Fantasia
          </template>
          {{ partner?.legal?.social_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-mobile-phone"></i>
            Telefone
          </template>
          {{ partner?.contact?.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-message"></i>
            Email
          </template>
          {{ partner?.email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            Proprietário
          </template>
          {{ partner?.legal?.owner_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-location-outline"></i>
            Endereço
          </template>
          {{ AdrToString }}
        </el-descriptions-item>
      </template>
      <template v-else>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-tickets"></i>
            Nome
          </template>
          {{ partner?.personal?.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-mobile-phone"></i>
            Telefone
          </template>
          {{ partner?.contact?.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-message"></i>
            Email
          </template>
          {{ partner?.email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            Data de Nascimento
          </template>
          {{
            partner?.personal?.birthday
              ? dateFormatter.format(new Date(partner?.personal?.birthday))
              : "-"
          }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-location-outline"></i>
            Endereço
          </template>
          {{ AdrToString }}
        </el-descriptions-item>
      </template>
    </el-descriptions>

    <el-descriptions
      class="margin-top"
      title="Informações do cliente"
      :column="3"
      border
      v-if="partner?.is_client"
    >
      <template>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-box"></i>
            Margem
          </template>
          {{ partner?.margin?.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Score Calculado
          </template>
          {{ partner?.score?.number }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Total Negociado
          </template>
          R$ 156.460,50
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Total em Aberto
          </template>
          <div class="danger">R$ 1.460,50</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Valor Médio Por Compra
          </template>
          R$ 1.670,50
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Crédito Liberado
          </template>
          {{
            partner?.credit?.total_amount
              ? currencyFormatter.format(Number(partner?.credit?.total_amount))
              : "sem limite"
          }}
        </el-descriptions-item>
      </template>
    </el-descriptions>

    <el-descriptions
      class="margin-top"
      title="Informações do CNPJ"
      :column="3"
      v-loading="isLoadingCNPJ"
      border
      v-if="partner?.type === 'legal'"
    >
      <template>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-box"></i>
            CNPJ
          </template>
          {{ partner?.legal?.cnpj }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-date"></i>
            Data da Abertura
          </template>
          {{ dateFormatter.format(new Date(cnpj?.founded)) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Capital Social
          </template>
          <div>
            {{ currencyFormatter.format(new Number(cnpj?.capital || 0)) }}
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-box"></i>
            Mapa
          </template>
          <el-button
            type="text"
            :disabled="!cnpj?.maps?.roads"
            @click="openImageViewer(cnpj?.maps?.roads)"
            >Visualizar</el-button
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-date"></i>
            StreetView
          </template>
          <el-button
            type="text"
            :disabled="!cnpj?.maps?.street"
            @click="openImageViewer(cnpj?.maps?.street)"
            >Visualizar</el-button
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Satélite
          </template>
          <div>
            <el-button
              type="text"
              :disabled="!cnpj?.maps?.satellite"
              @click="openImageViewer(cnpj?.maps?.satellite)"
              >Visualizar</el-button
            >
          </div>
        </el-descriptions-item>
      </template>
    </el-descriptions>

    <el-descriptions
      class="margin-top"
      title="Negociações com o parceiro"
      :column="2"
      border
    >
      <template>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-box"></i>
            Vendas
          </template>
          <el-button
            type="text"
            :disabled="!partner?.is_client"
            @click="openNegotiationsModal('sells', partner?.orders)"
            >Visualizar</el-button
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-box"></i>
            Compras
          </template>
          <el-button
            type="text"
            :disabled="!partner?.is_provider"
            @click="openNegotiationsModal('purcharses', partner?.purcharses)"
            >Visualizar</el-button
          >
        </el-descriptions-item>
      </template>
    </el-descriptions>

    <el-descriptions
      class="margin-top"
      title="Formas de pagamento autorizadas"
      :column="2"
      border
      v-if="partner?.is_client"
    >
      <template>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-box"></i>
            Cartão de crédito (a vista)
          </template>
          <el-switch
            :modelValue="paymentIsAllowedForClient('credit')"
            @update:modelValue="
              paymentIsAllowedForClient('credit')
                ? removeAllowedPayments('credit')
                : setAllowedPayments('credit')
            "
            active-text="Sim"
            inactive-text="Não"
          >
          </el-switch>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            PIX (a vista)
          </template>
          <el-switch
            :modelValue="paymentIsAllowedForClient('pix')"
            @update:modelValue="
              paymentIsAllowedForClient('pix')
                ? removeAllowedPayments('pix')
                : setAllowedPayments('pix')
            "
            active-text="Sim"
            inactive-text="Não"
          >
          </el-switch>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Crediário / Vencimento (no prazo)
          </template>
          <el-switch
            :modelValue="paymentIsAllowedForClient('cash')"
            @update:modelValue="
              paymentIsAllowedForClient('cash')
                ? removeAllowedPayments('cash')
                : setAllowedPayments('cash')
            "
            active-text="Sim"
            inactive-text="Não"
          >
          </el-switch>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Dinheiro (a vista)
          </template>
          <el-switch
            :modelValue="paymentIsAllowedForClient('money')"
            @update:modelValue="
              paymentIsAllowedForClient('money')
                ? removeAllowedPayments('money')
                : setAllowedPayments('money')
            "
            active-text="Sim"
            inactive-text="Não"
          >
          </el-switch>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Cartão de débito (a vista)
          </template>
          <el-switch
            :modelValue="paymentIsAllowedForClient('debit')"
            @update:modelValue="
              paymentIsAllowedForClient('debit')
                ? removeAllowedPayments('debit')
                : setAllowedPayments('debit')
            "
            active-text="Sim"
            inactive-text="Não"
          >
          </el-switch>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Boleto (no prazo)
          </template>
          <el-switch
            :modelValue="paymentIsAllowedForClient('billet')"
            @update:modelValue="
              paymentIsAllowedForClient('billet')
                ? removeAllowedPayments('billet')
                : setAllowedPayments('billet')
            "
            active-text="Sim"
            inactive-text="Não"
          >
          </el-switch>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Cheque (a vista)
          </template>
          <el-switch
            :modelValue="paymentIsAllowedForClient('check')"
            @update:modelValue="
              paymentIsAllowedForClient('check')
                ? removeAllowedPayments('check')
                : setAllowedPayments('check')
            "
            active-text="Sim"
            inactive-text="Não"
          >
          </el-switch>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Prazos p/ Boletos
          </template>
          <el-select
            remote
            v-model="partner.payment_days_billets"
            @change="updatePartner"
            :filter-method="filterPaymentDays"
            size="medium"
            allow-create
            filterable
            value-key="value"
            cleareable
          >
            <el-option
              v-for="item in PaymentDays"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Limite Parcelas Boletos
          </template>
          <base-input
            v-model="partner.max_billets_payments"
            type="money"
            v-on:keyup.enter="updatePartner"
            :minimum-fraction-digits="0"
          ></base-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Prazos p/ Crediário
          </template>
          <el-select
            remote
            v-model="partner.payment_days"
            @change="updatePartner"
            :filter-method="filterPaymentDays"
            size="medium"
            allow-create
            filterable
            value-key="value"
            cleareable
          >
            <el-option
              v-for="item in PaymentDays"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Carteira de Boletos
          </template>
          <el-select
            remote
            v-model="Partner.accountIds"
            @change="changePartnerAccounts"
            size="medium"
            cleareable
            multiple
          >
            <template #prefix>Carteiras:</template>
            <el-option
              v-for="item in FirmBankAccounts"
              :key="item.uid"
              :label="item.account_label"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-money"></i>
            Empresa p/ NF
          </template>
          <el-select
            v-model="Partner.firm_id"
            @change="updatePartner()"
            size="medium"
            cleareable
          >
            <el-option
              v-for="item in Firms"
              :key="item.uid"
              :label="item.name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-box"></i>
            Repassar Crédito Tributário:
          </template>
          <el-switch
            v-model="partner.use_tax_credit"
            @change="updatePartner"
            active-text="Sim"
            inactive-text="Não"
            :active-value="true"
            :inactive-value="false"
          >
          </el-switch>
        </el-descriptions-item>
      </template>
    </el-descriptions>

    <el-descriptions
      class="margin-top"
      title="Propriedades do Produtor"
      v-if="partner?.is_provider"
      :column="3"
      border
    >
      <template>
        <el-descriptions-item>
          <template #label> Valor por Qualidade </template>
          <div>
            <el-button type="text" @click="openProviderPrices()"
              >Visualizar</el-button
            >
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label> Taxa de Abate </template>
          <base-input
            type="money"
            v-model="partner.tax"
            label="R$"
            v-on:keyup.enter="updatePartner()"
          >
          </base-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label> Desconto </template>
          <base-input
            type="money"
            v-model="partner.deduction"
            label="%"
            v-on:keyup.enter="updatePartner()"
          >
          </base-input>
        </el-descriptions-item>
      </template>
    </el-descriptions>
    <image-viewer
      :imageUrl="imageUrl"
      :showModal="showImageViewer"
      @close-modal="showImageViewer = false"
    ></image-viewer>
    <negotiations-modal
      :negotiations="negotiations"
      :showModal="showNegotiationsModal"
      :type="negotiationType"
      @close-modal="showNegotiationsModal = false"
    ></negotiations-modal>
    <provider-prices-modal
      :partner="partner"
      :showModal="showProviderPricesModal"
      @update-prices="updatePrices($event)"
      @close-modal="showProviderPricesModal = false"
    ></provider-prices-modal>
  </el-card>
</template>
<script>
import ImageViewer from "./modals/ImageViewerModal.vue";
import NegotiationsModal from "./modals/NegotiationsModal.vue";
import ProviderPricesModal from "./modals/ProviderPricesModal.vue";
import BaseInput from "../components/BaseInput.vue";
import AccountService from "../services/accounts";
import PartnerService from "../services/partners";
import { dateFormatter, currencyFormatter } from "../utils/formatters";
import { notifySuccess, notifyError } from "../utils/notifiers";
import FirmService from "../services/firms";
export default {
  name: "PartnerDetails",
  components: {
    ImageViewer,
    NegotiationsModal,
    BaseInput,
    ProviderPricesModal,
  },
  data() {
    return {
      isLoading: true,
      isLoadingCNPJ: false,
      partner: null,
      size: "",
      imageUrl: "",
      firm_bank_accounts: null,
      negotiationType: "sells",
      showNegotiationsModal: false,
      negotiations: [],
      oldAccountIds: [],
      showProviderPricesModal: false,
      showImageViewer: false,
      currencyFormatter,
      dateFormatter,
      cnpj: {},
      firms: null,
    };
  },
  watch: {
    partner(partner) {
      if (partner.firm) this.partner.firm_id = partner.firm.uid;
    },
  },
  mounted() {
    this.loadPartnerData();
    this.fetchFirmBankAccounts();
    this.loadFirms();
  },
  methods: {
    async loadFirms() {
      const { firms } = await FirmService().index();

      if (firms) this.firms = firms;
    },
    async fetchFirmBankAccounts() {
      const { accounts } = await AccountService().index();

      if (accounts) this.firm_bank_accounts = accounts;
    },
    openImageViewer(url) {
      if (url) {
        this.imageUrl = url;
        this.showImageViewer = true;
      }
    },
    filterPaymentDays(v) {
      return [
        {
          label: `${v} dias`,
          value: v,
        },
      ];
    },
    setPartnerStatus(is_active) {
      this.partner.is_active = is_active;
      this.updatePartner();
    },
    updatePrices(prices) {
      this.partner.prices = prices;
      this.updatePartner();
    },
    async deletePartner() {
      const { partner, error } = PartnerService(this.partner.uid).delete();

      if (partner) {
        notifySuccess("Parceiro removido com sucesso");
        const router = this.$router;
        setTimeout(() => {
          router.push("/");
        }, 500);
      } else notifyError("Não foi possível remover o parceiro", error.message);
    },
    openProviderPrices() {
      this.showProviderPricesModal = true;
    },
    async updatePartner() {
      const { partner, error } = await PartnerService(this.partner.uid).update(
        this.partner
      );

      if (partner) {
        this.loadPartnerData();
        notifySuccess("Parceiro atualizado com sucesso");
      } else
        notifyError("Não foi possível atualizar o parceiro", error.message);
    },
    openNegotiationsModal(type, negotiations) {
      this.negotiationType = type;
      this.negotiations = negotiations;
      this.showNegotiationsModal = true;
    },
    async addPartnerAccount(accountIds) {
      const newAccountId = accountIds?.find(
        (id) => !this.oldAccountIds.includes(id)
      );

      if (newAccountId)
        return await PartnerService(this.partner.uid)
          .Accounts()
          .create({ firm_bank_account_id: newAccountId });
      else return null;
    },
    async removePartnerAccount(accountIds) {
      const oldAccountId = this.oldAccountIds?.find(
        (id) => !accountIds.includes(id)
      );

      if (oldAccountId)
        return await PartnerService(this.partner.uid)
          .Accounts(oldAccountId)
          .delete();
      else return null;
    },
    async changePartnerAccounts(accountIds) {
      if (accountIds?.length > this.oldAccountIds?.length)
        await this.addPartnerAccount(accountIds);
      else await this.removePartnerAccount(accountIds);

      this.loadPartnerData();

      notifySuccess("Parceiro atualizado com sucesso");
    },
    paymentIsAllowedForClient(payment) {
      return !!this.partner?.accepted_payments?.find((p) => p === payment);
    },
    setAllowedPayments(payment) {
      if (!this.partner.accepted_payments) this.partner.accepted_payments = [];
      this.partner.accepted_payments.push(payment);
      this.updatePartner();
    },
    removeAllowedPayments(payment) {
      if (!this.partner.accepted_payments) this.partner.accepted_payments = [];
      this.partner.accepted_payments = this.partner.accepted_payments.filter(
        (p) => p != payment
      );
      this.updatePartner();
    },
    async fetchPartnerDataFromCNPJ(_) {
      const _cnpj = Number.parseInt(onlyNumbers(_));
      if (Number.isNaN(_cnpj)) return;

      this.isLoadingCNPJ = true;

      const { cnpj } = await PartnerService().API(_cnpj).get({ api: "CNPJA" });

      if (cnpj) this.cnpj = cnpj;

      this.isLoadingCNPJ = false;
    },
    async loadPartnerData() {
      const { partner } = await PartnerService(this.$route.params.id).get();

      if (partner) {
        this.partner = partner;

        this.oldAccountIds = partner.bank_accounts?.map((a) => a.uid);
      }

      if (this.partner.type === "legal" && "cnpj" in this.partner.legal)
        this.fetchPartnerDataFromCNPJ(this.partner.legal.cnpj);

      this.isLoading = false;
    },
  },
  computed: {
    AdrToString() {
      if (this?.partner?.addr) {
        const a = this.partner.addr;
        return `${a.street || ""}, ${a.number || "S/N"}. ${a.place}. ${
          a.city
        }/${a.state}`;
      } else return "";
    },
    Partner() {
      const partner = this.partner;
      partner.accountIds = partner.bank_accounts?.map((a) => a.uid);
      return partner;
    },
    FirmBankAccounts() {
      return this.firm_bank_accounts;
    },
    Firms() {
      return this.firms || [];
    },
    PaymentDays() {
      const client_payment_days = [];
      const p = this.Partner.payment_days;
      if (!this.$store.state.constants.payment_days?.find((f) => f.value === p))
        client_payment_days.push({ value: p, label: `${p} dias` });

      const orderedDays = [
        ...this.$store.state.constants.payment_days,
        ...client_payment_days,
      ].sort((a, b) => a.value - b.value);

      return orderedDays;
    },
  },
};
const onlyNumbers = (s) => s.replace(/[^0-9]/g, "");
</script>
<style>
.margin-top {
  margin-top: 15px;
}
.danger {
  color: red;
}
</style>
