<template>
  <el-dialog v-model="shouldShow" :title="ModalTitle">
    <el-table>
      <el-table-column></el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
export default {
  props: ["negotiations", "showModal", "type"],
  emits: ["close-modal"],
  data() {
    return {
      hasImage: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    ModalTitle() {
      return this.type === "sells" ? "Vendas" : "Compras";
    },
  },
  methods: {},
  name: "NegotiationsModal",
};
</script>
<style>
</style>